
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import Filters from "./crafted/widgets/Filters.vue";
import FilterTags from "./crafted/widgets/FilterTags.vue";
import eventsApi from "@/core/services/EventsApi";

export default defineComponent({
    name: "builder",
    components: { Filters, FilterTags },
    data() {
        return {
            tagsKey: 0,
            activeFilters: {},
            filtersPayload: {},
            filtersToShow: {
                age: false,
                bailliage: true,
                commander: false,
                country: true,
                dues: false,
                function: true,
                gender: true,
                grade: true,
                medal: false,
                member_type: false,
                other_association: true,
                period: true,
                postal_code: true,
                state: true,
                title: true,
                activity_sector: true,
                plaque: false,
                credit_card: false,
                institution_type: false,
                food_style: false,
                stars: false,
                price_category_promotion: false,
                price_category_due: false,
                type_due: false,
                relevant_due_year: false,
                type_chapitre: true,
                amount_accr: false,
                type_accr: false,
                biller_period: false,
                biller_actual_balance: false,
                biller_initial_balance: false,
            },
            loading: true,
            eventSearch: "",
            events: [
                {
                    id: 0,
                    name: "2021 only - Concours International des Jeunes Chefs Rôtisseurs 2021",
                    type: "All members",
                    edition: "Paris 2021 / Jeunes Chefs Rôtisseurs	",
                    dates: "01 Jun 2018 - 31 Aug 2018",
                },
                {
                    id: 1,
                    name: "Competitor Event test",
                    type: "Only Competitors",
                    edition: "Frankfurt 2017 / Jeunes Chefs Rôtisseurs",
                    dates: "20 Sep 2021 - 29 Sep 2021",
                },
            ] as any,
            currentPage: 1,
            eventsCount: 0,
            totalPages: 0,
            selectedEvents: [] as any,
            actualFilter: "",
            actualCol: "",
            eventsTh: [
                { label: "Name", class: "min-w-60px", colName: "name" },
                { label: "Type", class: "min-w-60px", colName: "type" },
                { label: "Edition", class: "min-w-60px", colName: "edition" },
                { label: "Dates", class: "min-w-60px", colName: "dates" },
            ],
            perm_pos: "",
        };
    },
    setup() {
        const router = useRouter();
        const resultsPerPage = 10;

        onMounted(() => {
            setCurrentPageBreadcrumbs("Events list", []);
        });

        return { router, resultsPerPage };
    },
    mounted() {
        this.getEvents();
    },
    methods: {
        handleSizeChange() {
            this.getEvents();
        },
        getEvents() {
            this.loading = true;
            eventsApi.listEvents({ p: this.currentPage, presult: this.resultsPerPage }).then((res: any) => {
                this.events = res.data.object_list;
                this.totalPages = res.data.page_count;
                this.eventsCount = res.data.object_count;
                this.loading = false;
            });
        },
        addEvent() {
            this.router.push({ name: "event-details" });
        },
        editEvent(id: any) {
            this.router.push({ name: "event-details", params: { event_id: id } });
        },
        deleteEvents(id?: any) {
            if (id) {
                eventsApi.deleteEvent(id).then((res: any) => {
                    if (res.success) {
                        ElMessage({
                            message: "Successfully deleted event!",
                            type: "success",
                        });
                        this.getEvents();
                    } else {
                        ElMessage({
                            message: "An error occured while deleting the event.",
                            type: "error",
                        });
                    }
                });
            }
        },
        sortColumn(column: string, id: number) {
            var arrows = document.getElementById("chevrons" + id);

            // Hide all arrows
            Array.from(document.getElementsByClassName("chevrons-container") as any).map((filter: any) => {
                filter.style.display = "none";
            });

            // Display the right arrows
            if (arrows) arrows.style.display = "flex";

            // If we click for the first time on a column OR if we change the column
            if (this.actualCol === "" || this.actualCol !== column) {
                this.actualCol = column;
                this.actualFilter = "asc";
                this.handleChevron("show", "up");
                this.handleChevron("hide", "down");

                // Else if we click on the same column
            } else if (this.actualCol === column) {
                if (this.actualFilter === "asc") {
                    this.handleChevron("hide", "up");
                    this.handleChevron("show", "down");
                    this.actualFilter = "desc";
                } else if (this.actualFilter === "desc") {
                    this.handleChevron("show", "up");
                    this.handleChevron("hide", "down");
                    this.actualFilter = "asc";
                }
            }
            this.getEvents();
        },
        handleChevron(action: string, direction: string) {
            var chevrons: any;

            if (direction === "up") chevrons = Array.from(document.getElementsByClassName("fa-chevron-up") as any);
            else if (direction === "down") chevrons = Array.from(document.getElementsByClassName("fa-chevron-down") as any);

            if (action === "show")
                chevrons.map((chevron: any) => {
                    chevron.classList.add("active-chevron");
                });
            else if (action === "hide")
                chevrons.map((chevron: any) => {
                    chevron.classList.remove("active-chevron");
                });
        },
        handlePagination(pageNumber: any) {
            this.selectedEvents = [];
            var topCheckbox = document.getElementById("topCheckbox") as any;
            topCheckbox.checked = false;

            this.currentPage = pageNumber;
            this.getEvents();
        },
        checkAll() {
            var topCheckbox = document.getElementById("topCheckbox") as any;
            var checkboxes = Array.from(document.getElementsByClassName("custom-checkbox-events") as any);

            this.selectedEvents = [];

            if (topCheckbox.checked) {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = true;
                });

                this.events.map((event: any) => {
                    this.selectedEvents.push(event.id);
                });
            } else {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = false;
                });
                this.selectedEvents = [];
            }
        },
        handleActionSelect(event: any) {
            var focusedCheckbox = document.getElementById("checkbox" + event.id) as any;

            if (focusedCheckbox?.checked) {
                this.selectedEvents.push(event);
            } else {
                this.selectedEvents.map((localEvent: any) => {
                    if (event.id === localEvent.id) {
                        this.selectedEvents.splice(this.selectedEvents.indexOf(localEvent), 1);
                    }
                });
            }
        },
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        },
    },
});
